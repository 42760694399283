import * as Sentry from '@sentry/browser';
import packageJson from '../../package.json';
import config from '../config/config';
export const initializeSentry = () => {
    Sentry.init({
        dsn: config.SENTRY_DSN,
        release: `${packageJson.version}`,
        debug: config.APP_ENV !== 'production',
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.captureConsoleIntegration({ levels: ['error'] })
        ],
        sampleRate: 1,
        tracesSampleRate: 0.1
    });
};
