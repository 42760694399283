import { html } from 'lit';

const labelWidthStyle = 'width: 100px';

export function Table() {
  return html`
    <h2 class="mb-8">Table</h2>

    <div class="flex flex-row mb-4 items-center">
      <div class="pin-text-title pin-text-title--small mr-2" style="${labelWidthStyle}">
        Example table
      </div>
      <div class="flex-1">
        <div class="pin-table w-full">
          <div class="pin-table-header pin-table-row">
            <div class="pin-table-cell" style="width: 50px"></div>
            <div class="pin-table-cell flex items-center text-gray-800" style="width: 120px;">
              <span>Forslag</span>
            </div>
            <div class="pin-table-cell flex items-center flex-1 text-gray-800">
              <span>Tilfeldig tekst</span>
            </div>
            <div class="pin-table-cell flex items-center text-gray-800" style="width: 135px;">
              <span>Prosenter</span>
            </div>
            <div class="pin-table-cell flex items-center text-gray-800" style="width: 175px;">
              <span>Noe annet</span>
            </div>
          </div>
          <div class="pin-table-body">
            ${[1, 2, 3, 4, 5].map((i) => {
              return html`
                <div class="pin-table-row ${i % 2 ? 'bg-gray-300' : 'bg-white'}">
                  <div class="pin-table-cell" style="width: 50px; padding: 5px 10px;">
                    <input type="checkbox" style="width: 20px; height: 20px" />
                  </div>
                  <div class="pin-table-cell" style="width: 120px;">Eksempel ${i}</div>
                  <div class="pin-table-cell flex-1">Noe annet ${i}</div>
                  <div class="pin-table-cell text-center" style="width: 135px;">12%</div>
                  <button
                    class="pin-table-cell bg-transparent text-gray-800 outline-none cursor-pointer"
                    aria-label="Sorter listen basert på riktige svar"
                    style="width: 175px;"
                  >
                    <div class="flex items-center">
                      <span>Noe tekst</span>
                    </div>
                  </button>
                </div>
              `;
            })}
          </div>
        </div>
      </div>
    </div>
  `;
}
