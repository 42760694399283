var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html } from 'lit';
import { customElement, property, state, query } from 'lit/decorators.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { orgPlayback, orgChevronOpen, orgWarn } from '@nrk/origo';
import BaseElement from '../base/base-element';
import LabelledInput from '../form/labelled-input';
import { on, off, trigger } from '../../utils/events';
import LoaderInline from '../loader-inline';
import { ERROR_DIALOG, GUESSES_FETCH, GUESSES_UPDATED, PARTICIPANT_REVEAL } from '../../events';
import { getServerErrorContent } from '../dialog/error-dialog';
let ParticipantSidebar = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class ParticipantSidebar extends BaseElement {
    constructor() {
        super();
        this.isPaused = false;
        this.isFetchingGuesses = false;
        this.isRevealingParticipant = false;
        this.updatedTimestamp = null;
        this.failedToUpdate = false;
        this.onClick = this.onClick.bind(this);
        this.onGuessesUpdated = this.onGuessesUpdated.bind(this);
    }
    connectedCallback() {
        super.connectedCallback();
        this.addEventListener('click', this.onClick);
        on(GUESSES_UPDATED, this.onGuessesUpdated);
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        this.removeEventListener('click', this.onClick);
        off(GUESSES_UPDATED, this.onGuessesUpdated);
    }
    onClick(e) {
        if (e.target.closest('button[disabled]')) {
            e.preventDefault();
            e.stopPropagation();
            return;
        }
    }
    onGuessesUpdated(time) {
        this.isFetchingGuesses = false;
        if (time === null) {
            trigger(ERROR_DIALOG, {
                showDialog: true,
                title: 'En feil har skjedd',
                content: getServerErrorContent()
            });
            this.failedToUpdate = true;
        }
        else {
            this.failedToUpdate = false;
            this.updatedTimestamp = time;
        }
        this.requestUpdate();
    }
    revealParticipant(e) {
        trigger(PARTICIPANT_REVEAL, this.inputEl.value);
        this.closeDropdown(e);
    }
    getGuesses() {
        this.isFetchingGuesses = true;
        trigger(GUESSES_FETCH);
    }
    closeDropdown(e) {
        const dropdown = e.target.closest('pin-dropdown');
        if (dropdown) {
            dropdown.hidden = true;
        }
    }
    render() {
        const isRevealed = this.participant && this.participant.realName && this.participant.realName.length > 0;
        return html `
      ${this.failedToUpdate && this.updatedTimestamp
            ? html `
            <div class="flex items-center p-2 bg-yellow-400 text-xs mb-2">
              ${unsafeHTML(orgWarn)} Sist oppdatert
              ${new Date(this.updatedTimestamp).toLocaleString()}
            </div>
          `
            : ''}
      ${!this.failedToUpdate && this.updatedTimestamp
            ? html `
            <div class="text-xs mb-2">
              Sist oppdatert ${new Date(this.updatedTimestamp).toLocaleString()}
            </div>
          `
            : ''}
      <button
        class="mb-8 flex w-full justify-center items-center pin-button pin-button--secondary"
        @click="${this.getGuesses}"
        ?disabled="${this.isFetchingGuesses}"
      >
        ${this.isFetchingGuesses
            ? html `
              ${LoaderInline()}
              <span class="block ml-2">Oppdaterer liste...</span>
            `
            : html `
              ${unsafeHTML(orgPlayback)}
              <span class="block ml-2">Oppdater liste</span>
            `}
      </button>
      ${isRevealed
            ? html `
            <span
              class="pin-status-pill pin-status-pill--maskorama block w-full justify-center text-center"
            >
              Avslørt
            </span>
          `
            : html `
            <button
              class="mb-8 flex w-full justify-center items-center pin-button pin-button--success"
              ?disabled="${!this.isPaused || this.isRevealingParticipant}"
              title="${this.isPaused
                ? ''
                : 'Kan bare avsløre deltaker når avstemningen er satt på pause'}"
            >
              ${this.isRevealingParticipant
                ? html `
                    ${LoaderInline()}
                    <span class="block ml-2">Avslører ...</span>
                  `
                : html `
                    <span class="mr-2">Avslør deltaker</span>
                    ${unsafeHTML(orgChevronOpen)}
                  `}
            </button>
            <pin-dropdown class="pin-dropdown" js-dropdown-reveal hidden>
              <div class="mb-4">Du er i ferd med å avsløre hvem denne deltakeren er.</div>
              <div class="mb-4">Dette vil bli synlig for alle brukerne!</div>

              ${LabelledInput({
                label: 'Skriv deltakerens identitet',
                name: 'reveal-participant'
            })}
              <div class="mt-8 flex flex-row items-center">
                <button
                  class="pin-button pin-button--transparent mr-2"
                  @click="${this.closeDropdown}"
                >
                  Avbryt
                </button>
                <button
                  class="pin-button pin-button--critical flex flex-row items-center"
                  @click="${this.revealParticipant}"
                  ?disabled="${this.isRevealingParticipant}"
                >
                  ${this.isRevealingParticipant
                ? html `
                        ${LoaderInline()}
                        <span class="block ml-2">Avslører ...</span>
                      `
                : html ` <span class="block ml-2">Avslør</span> `}
                </button>
              </div>
            </pin-dropdown>
          `}
      <pin-start-round-checklist class="mt-4"></pin-start-round-checklist>
    `;
    }
};
__decorate([
    property({ type: Object })
], ParticipantSidebar.prototype, "participant", void 0);
__decorate([
    property({ type: Boolean })
], ParticipantSidebar.prototype, "isPaused", void 0);
__decorate([
    property({ type: Boolean })
], ParticipantSidebar.prototype, "isFetchingGuesses", void 0);
__decorate([
    property({ type: Boolean })
], ParticipantSidebar.prototype, "isRevealingParticipant", void 0);
__decorate([
    state()
], ParticipantSidebar.prototype, "updatedTimestamp", void 0);
__decorate([
    state()
], ParticipantSidebar.prototype, "failedToUpdate", void 0);
__decorate([
    query('[name="reveal-participant"]')
], ParticipantSidebar.prototype, "inputEl", void 0);
ParticipantSidebar = __decorate([
    customElement('pin-participant-sidebar')
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], ParticipantSidebar);
