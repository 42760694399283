import { html } from 'lit';

export function Buttons() {
  return html`
    <h2 class="mb-8">Buttons</h2>
    <div class="flex flex-row mb-4">
      <div class="flex flex-col items-center">
        <div class="mb-3 pin-text-title pin-text-title--small">Primary</div>
        <button class="mr-2 pin-button pin-button--primary">Slå sammen forslag</button>
      </div>
      <div class="flex flex-col items-center">
        <div class="mb-3 pin-text-title pin-text-title--small">Secondary</div>
        <button class="mr-2 pin-button pin-button--secondary">Slå sammen forslag</button>
      </div>
      <div class="flex flex-col items-center">
        <div class="mb-3 pin-text-title pin-text-title--small">Suggestive</div>
        <button class="mr-2 pin-button pin-button--suggestive">Slå sammen forslag</button>
      </div>
      <div class="flex flex-col items-center">
        <div class="mb-3 pin-text-title pin-text-title--small">Critical</div>
        <button class="mr-2 pin-button pin-button--critical">Slå sammen forslag</button>
      </div>
      <div class="flex flex-col items-center">
        <div class="mb-3 pin-text-title pin-text-title--small">Success</div>
        <button class="mr-2 pin-button pin-button--success">Slå sammen forslag</button>
      </div>
      <div class="flex flex-col items-center">
        <div class="mb-3 pin-text-title pin-text-title--small">Transparent</div>
        <button class="mr-2 pin-button pin-button--transparent">Slå sammen forslag</button>
      </div>
    </div>
    <div class="flex flex-row mb-4">
      <button class="mr-2 pin-button pin-button--primary" disabled>Slå sammen forslag</button>
      <button class="mr-2 pin-button pin-button--secondary" disabled>Slå sammen forslag</button>
      <button class="mr-2 pin-button pin-button--suggestive" disabled>Slå sammen forslag</button>
      <button class="mr-2 pin-button pin-button--critical" disabled>Slå sammen forslag</button>
      <button class="mr-2 pin-button pin-button--success" disabled>Slå sammen forslag</button>
      <button class="mr-2 pin-button pin-button--transparent" disabled>Slå sammen forslag</button>
    </div>
  `;
}
