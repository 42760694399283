import { html } from 'lit';
export default function Tabs(content) {
    return html `
    <nav class="pin-tabs">
      <div class="px-4 mx-auto w-full max-w-8xl">
        <div class="flex mr-auto">${content}</div>
      </div>
    </nav>
  `;
}
