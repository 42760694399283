export const PARTICIPANT_REVEAL = 'participant:reveal';
export const PARTICIPANT_CHANGE_REVEAL = 'participant:change-reveal';
export const PARTICIPANT_REVERT_REVEAL = 'participant:revert-reveal';
export const PARTICIPANTS_SAVE = 'participants:save';
export const GUESSES_FETCH = 'guesses:fetch';
export const GUESSES_UPDATED = 'guesses:updated';
export const GUESSES_SET_PAGE = 'guesses:set-page';
export const ERROR_DIALOG = 'error:dialog';
export const DRAG_AND_DROP_REORDER = 'dnd:reorder';
export const UPDATE_CHECKLIST = 'checklist:update';
