import { html } from 'lit';

const labelWidthStyle = 'width: 100px';

export function Texts() {
  return html`
    <h2 class="mb-8">Texts</h2>

    <div class="flex flex-row mb-4 items-center">
      <div class="pin-text-title pin-text-title--small mr-2" style="${labelWidthStyle}">
        Header 1
      </div>
      <div class="pin-text-header-1">Lorem ipsum dolor sit amet</div>
    </div>

    <div class="flex flex-row mb-4 items-center">
      <div class="pin-text-title pin-text-title--small mr-2" style="${labelWidthStyle}">
        Header 2
      </div>
      <div class="pin-text-header-2">Lorem ipsum dolor sit amet</div>
    </div>

    <div class="flex flex-row mb-4 items-center">
      <div class="pin-text-title pin-text-title--small mr-2" style="${labelWidthStyle}">
        Ingress
      </div>
      <div class="pin-text-ingress">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras a suscipit arcu, a dapibus
        est.
      </div>
    </div>

    <div class="flex flex-row mb-4 items-center">
      <div class="pin-text-title pin-text-title--small mr-2" style="${labelWidthStyle}">Label</div>
      <div class="pin-text-label">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras a suscipit arcu, a dapibus
        est.
      </div>
    </div>

    <div class="flex flex-row mb-4 items-center">
      <div class="pin-text-title pin-text-title--small mr-2" style="${labelWidthStyle}">
        Header label
      </div>
      <div class="pin-text-header-label">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras a suscipit arcu, a dapibus
        est.
      </div>
    </div>
  `;
}
