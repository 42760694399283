import { html } from 'lit';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { orgFavoriteOn } from '@nrk/origo';

const labelWidthStyle = 'width: 100px';

export function Tile() {
  return html`
    <h2 class="mb-8">Tiles</h2>

    <div class="flex flex-row mb-4 items-center">
      <div class="pin-text-title pin-text-title--small mr-2" style="${labelWidthStyle}">
        Empty tile
      </div>
      <div class="flex-1">
        <pin-tile></pin-tile>
      </div>
    </div>

    <div class="flex flex-row mb-4 items-center">
      <div class="pin-text-title pin-text-title--small mr-2" style="${labelWidthStyle}">
        Example tile
      </div>
      <div class="flex-1">
        <pin-tile>
          <img src="https://picsum.photos/50/50" width="50" height="50" style="object-fit: cover" />
          <div class="flex-1 mx-4">Andungen</div>
          <div class="mx-4 flex items-center justify-start" style="width: 90px;">
            ${unsafeHTML(orgFavoriteOn)}
            <span class="ml-2">1234</span>
          </div>
          <div class="mx-4 flex items-center justify-start" style="width: 75px;">
            <span class="ml-2">5678</span>
          </div>
        </pin-tile>
      </div>
    </div>
  `;
}
