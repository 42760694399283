import BaseElement from '../components/base/base-element';
import { html } from 'lit';
import { Buttons } from './components/buttons';
import { Texts } from './components/texts';
import { Tile } from './components/tile';
import { Table } from './components/table';
import Masthead from '../components/masthead/masthead';
import Banner from '../components/banner/banner';
import Tabs from '../components/tabs/tabs';
import { repeat } from 'lit/directives/repeat.js';
import { on, off } from '../utils/events';
import { DRAG_AND_DROP_REORDER } from '../events';

class ComponentsListPage extends BaseElement {
  constructor() {
    super();

    this.exampleList = [
      {
        id: 1,
        value: 'Draggable_1'
      },
      {
        id: 2,
        value: 'Draggable_2'
      },
      {
        id: 3,
        value: 'Draggable_3'
      }
    ];
    this.onReorder = this.onReorder.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();

    on(DRAG_AND_DROP_REORDER, this.onReorder);
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    off(DRAG_AND_DROP_REORDER, this.onReorder);
  }

  onReorder({ from, to }) {
    const fixedTo = from < to ? to - 1 : to;

    const list = [...this.exampleList.slice(0, from), ...this.exampleList.slice(from + 1)];

    this.exampleList = [...list.slice(0, fixedTo), this.exampleList[from], ...list.slice(fixedTo)];

    this.requestUpdate();
  }

  render() {
    return html`
      <div class="block container mx-auto px-4 mt-10 max-w-8xl">
        <div class="mb-16">${Buttons()}</div>
        <div class="mb-16">${Texts()}</div>
        <div class="mb-16">${Tile()}</div>
        <div class="mb-16">${Table()}</div>
        <div class="mb-16">
          <h2 class="mb-8">Masthead</h2>
          <div class="flex flex-row mb-4 items-center">
            <div class="pin-text-title pin-text-title--small mr-2" style="width: 100px;">
              Masthead
            </div>
            <div class="flex-1">${Masthead()}</div>
          </div>
        </div>
        <div class="mb-16">
          <h2 class="mb-8">Banner</h2>
          <div class="flex flex-row mb-4 items-center">
            <div class="pin-text-title pin-text-title--small mr-2" style="width: 100px;">
              Empty Banner
            </div>
            <div class="flex-1">${Banner()}</div>
          </div>
          <div class="flex flex-row mb-4 items-center">
            <div class="pin-text-title pin-text-title--small mr-2" style="width: 100px;">
              Example Banner
            </div>
            <div class="flex-1">
              ${Banner(html`
                <h2 class="pin-text-header-1">Artister</h2>
                <span class="pin-text-ingress">
                  Velg artisten du ønsker å se detaljer på eller forslag fra publikum
                </span>
              `)}
            </div>
          </div>
        </div>
        <div class="mb-16">
          <h2 class="mb-8">Tabs</h2>
          <div class="flex flex-row mb-4 items-center">
            <div class="pin-text-title pin-text-title--small mr-2" style="width: 100px;">
              Empty tabs
            </div>
            <div class="flex-1">${Tabs()}</div>
          </div>
          <div class="flex flex-row mb-4 items-center">
            <div class="pin-text-title pin-text-title--small mr-2" style="width: 100px;">
              Example tabs
            </div>
            <div class="flex-1">
              ${Tabs(html`
                <a href="#" class="pin-tab pin-tab--is-active"> Forslag </a>
                <a href="#" class="pin-tab"> Innstillinger </a>
              `)}
            </div>
          </div>
        </div>
        <div class="mb-16">
          <h2 class="mb-8">Status pill</h2>

          <div class="flex flex-row mb-4 items-center">
            <div class="pin-text-title pin-text-title--small mr-2" style="width: 100px;">
              Example
            </div>
            <div>
              <div class="pin-status-pill">Avstemning satt på pause</div>
            </div>
          </div>
        </div>
        <div class="mb-16">
          <h2 class="mb-8">Drag and drop</h2>
          <pin-dnd>
            <pin-droppable></pin-droppable>
            ${repeat(
              this.exampleList,
              (x) => x.id,
              (x) => {
                return html`
                  <pin-draggable>
                    <div>${x.value}</div>
                  </pin-draggable>
                  <pin-droppable></pin-droppable>
                `;
              }
            )}
          </pin-dnd>
        </div>
      </div>
    `;
  }
}

// Define if not already defined
if (!window.customElements.get('pin-components')) {
  window.customElements.define('pin-components', ComponentsListPage);
}
